<template>
  <div class="">
    <!-- section-1  -->
    <v-card class="bgs rounded-0" flat  >
      <v-container class="pa-0">
        <v-row align="center" class="ma-0">
          <v-col cols="12" md="5" sm="12" class="px-0">
            <div class="left">
              <p class="titles mb-0">{{ $t("开始全球销售") }}</p>
              <p class="font24 mb-0">
                {{ $t("用taoify工具创建您的商店，打造和发展您的品牌") }}
              </p>
              <div class="mt-8">
                <router-link to="/sign-up" class="d-flex">
                  <v-card
                    class="primary-background font18 px-12 rounded-pill cursor white--text"
                    height="51"
                    flat
                    >{{ $t("立即免费开通") }}</v-card
                  >
                </router-link>
              </div>
              <p class="mb-0 text-caption mt-3">
                {{ $t("免费开通您的taoify在线商店，无需任何费用") }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" md="7" sm="12" class="pr-0 d-flex justify-end py-10">
            <div>
              <v-img
              class="gif"
                max-width="638"
                src="@/assets/images/solution/image1.png"
              ></v-img>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-container class="pa-0">
      <p class="primary--text mt-5 font20 text-center mb-0" style="">
        {{ $t("建立在线商店") }}
      </p>
      <p class="mb-0 mt-2 font32 font-weight-bold text-center">
        {{ $t("创建您的商店") }}
      </p>
      <p class="mb-0 mt-2 text-center">
        {{ $t("只需简单几步，就能帮助您开通taoify跨境在线商店") }}
      </p>
      <v-card flat class="mt-11" color="#F1F0FF">
        <v-img
          max-width="1200"
          src="@/assets/images/solution/image2.png"
        ></v-img>
      </v-card>
      <v-row class="my-0 mt-8">
        <v-col cols="6" md="3">
          <div class="font20 font-weight-bold d-flex align-center">
            <v-card
              flat
              width="16"
              height="16"
              color="primary"
              class="rounded-1 mr-2"
            ></v-card>
            {{ $t("选择店铺类型") }}
          </div>
          <p class="my-3">
            {{ $t("选择最适合你的类型和套餐，开启建店第一步！") }}
          </p>
        </v-col>
        <v-col cols="6" md="3">
          <div class="font20 font-weight-bold d-flex align-center">
            <v-card
              flat
              width="16"
              height="16"
              color="primary"
              class="rounded-1 mr-2"
            ></v-card>
            {{ $t("选择售卖商品") }}
          </div>
          <p class="my-3">
            {{ $t("超多热门商品包，无需上传，一键开启你的店铺销售") }}
          </p>
        </v-col>
        <v-col cols="6" md="3">
          <div class="font20 font-weight-bold d-flex align-center">
            <v-card
              flat
              width="16"
              height="16"
              color="primary"
              class="rounded-1 mr-2"
            ></v-card>
            {{ $t("选择店铺模板") }}
          </div>
          <p class="my-3">
            {{
              $t(
                "精选电商主题模板一键应用，卡片式操作，无需设计能力，快速拥有一个能提现您品牌调性、安全、稳定的在线商店"
              )
            }}
          </p>
        </v-col>
        <v-col cols="6" md="3">
          <div class="font20 font-weight-bold d-flex align-center">
            <v-card
              flat
              width="16"
              height="16"
              color="primary"
              class="rounded-1 mr-2"
            ></v-card>
            {{ $t("编辑店铺信息") }}
          </div>
          <p class="my-3">
            {{
              $t("编辑一个简洁好记的店铺信息，进行注册，方便用户进行检索商店")
            }}
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-card flat color="#f7f7f7" class="text-center">
      <img src="@/assets/images/solution/image3.png" alt="" style="max-width:100%">
    </v-card>
    <!-- <v-img
      max-width="1920"
      class="mt-4"
      max-height="629"
      src="@/assets/images/solution/image3.png"
    ></v-img> -->
    <v-container class="pa-0">
      <p class="primary--text mt-5 font20 text-center mb-0" style="">
        {{ $t("自由选择中国淘宝，1688产品库热门商品") }}
      </p>
      <p class="mb-0 mt-2 font32 font-weight-bold text-center">
        {{ $t("全球销售您的产品") }}
      </p>
      <p class="mb-0 mt-2 text-center">
        {{ $t("自由上传自营商品到taoify商店") }}
      </p>
      <v-card flat class="mt-11" color="#F1F0FF">
        <v-img
          max-width="1200"
          src="@/assets/images/solution/image4.png"
        ></v-img>
      </v-card>
    </v-container>
    <v-container class="mt-11">
      <v-row justify="center" justify-sm="center" justify-md="space-between">
        <v-card
          width="220"
          min-height="230"
          class="px-5 py-6 rounded-xl text-center elevation-5 mb-5"
        >
          <v-img
            width="44"
            class="mx-auto"
            src="@/assets/images/solution/icon1.png"
          ></v-img>
          <p class="font20 weight500 mt-3 mb-0">{{ $t("在线销售") }}</p>
          <p class="mb-0 mt-3 text-left">
            {{
              $t(
                "使用taoify内置SEO、自动化营销工具、谷歌、Facebook等主要营销渠道集成的应用程序"
              )
            }}
          </p>
        </v-card>
        <v-card
          width="220"
          min-height="230"
          class="px-5 py-6 rounded-xl text-center elevation-5 mb-5"
        >
          <v-img
            width="44"
            class="mx-auto"
            src="@/assets/images/solution/icon2.png"
          ></v-img>
          <p class="font20 weight500 mt-3 mb-0">{{ $t("订单和物流管理") }}</p>
          <p class="mb-0 mt-3 text-left">
            {{
              $t(
                "taoify管理和配送来自您商店的订单，向消费者发送订单物流信息，让消费者获得更好的购物体验"
              )
            }}
          </p>
        </v-card>
        <v-card
          width="220"
          min-height="230"
          class="px-5 py-6 rounded-xl text-center elevation-5 mb-5"
        >
          <v-img
            width="44"
            class="mx-auto"
            src="@/assets/images/solution/icon3.png"
          ></v-img>
          <p class="font20 weight500 mt-3 mb-0">{{ $t("数据管理") }}</p>
          <p class="mb-0 mt-3 text-left">
            {{
              $t(
                "所有来自您商店的订单、财务、会员数据都可以实时查看，帮助您更好的开展业务"
              )
            }}
          </p>
        </v-card>
        <v-card
          width="220"
          min-height="230"
          class="px-5 py-6 rounded-xl text-center elevation-5 mb-5"
        >
          <v-img
            width="44"
            class="mx-auto"
            src="@/assets/images/solution/icon4.png"
          ></v-img>
          <p class="font20 weight500 mt-3 mb-0">{{ $t("在线客服") }}</p>
          <p class="mb-0 mt-3 text-left">
            {{ $t("直接在商店里与消费者沟通，提升千倍销量，接触并留住消费者") }}
          </p>
        </v-card>
        <v-card
          width="220"
          min-height="230"
          class="px-5 py-6 rounded-xl text-center elevation-5 mb-5"
        >
          <v-img
            width="44"
            class="mx-auto"
            src="@/assets/images/solution/icon5.png"
          ></v-img>
          <p class="font20 weight500 mt-3 mb-0">{{ $t("业务资金更安全") }}</p>
          <p class="mb-0 mt-3 text-left">
            {{ $t("可直接绑定收款账户，随时查看收款资金") }}
          </p>
        </v-card>
      </v-row>
    </v-container>
        <v-card class="rounded-0 text-center py-5" flat style="background: linear-gradient(207deg, #A692FD 0%, #6355FF 100%);margin-top:40px;">
      <p class="font32 white--text mb-2 text-center">
        {{$t('立即建立您的taoify商店')}}
      </p>
      <p class="font20 mb-2 white--text text-center">
        {{$t('免费使用taoify,并开始探索、启动、运营和发展业务所需要的工具和服务')}}
      </p>
      <v-btn large class="primary--text rounded-lg" to="/sign-up" depressed>{{$t('立即免费开通')}}</v-btn>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data: () => ({}),
  methods: {},
};
</script>

<style lang="scss">
.cover-upload-wrapper {
  display: flex;
  align-items: flex-end;

  .cover-upload {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 0;
  }
  input {
    display: none;
  }
}
</style>